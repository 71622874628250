import {
  Box,
  Button,
  Circle,
  Heading,
  Img,
  LightMode,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
  VisuallyHidden,
} from "@chakra-ui/react";
import { CHAT_LINK } from "./constants";

export const Hero = () => {
  return (
    <Box>
      <Box as="section" bg="brandDark" color="white" py="7.5rem">
        <Box
          maxW={{ base: "xl", md: "5xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Box textAlign="center">
            <Heading
              as="h1"
              size="lg"
              fontWeight="extrabold"
              maxW="56rem"
              mx="auto"
              lineHeight="1.2"
              letterSpacing="tight"
            >
              Give your customers analytics, insights and exports that they'll
              love
            </Heading>
            <Text fontSize="xl" mt="4" maxW="48rem" mx="auto">
              Add all the data products your customers want - charts,
              dashboards, data exports, and more - in record time with minimal
              code.
            </Text>
          </Box>
          <Stack
            justify="center"
            direction="column"
            align="center"
            spacing={4}
            mt="10"
          >
            <iframe
              width="800"
              height="520"
              src="https://www.youtube.com/embed/LNZGCl7lQMU?si=0tmGKMOmkWrTN7m-"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Stack>
          <Stack
            justify="center"
            direction={{ base: "column", md: "row" }}
            mt="10"
            mb="10"
            spacing="4"
          >
            <LightMode>
              <Button
                as="a"
                href="#"
                size="xl"
                colorScheme="whiteAlpha"
                px="8"
                fontWeight="bold"
                fontSize="md"
                onClick={() => (window.location.href = CHAT_LINK)}
              >
                Chat With Us
              </Button>
            </LightMode>
          </Stack>

          {/*
          <Box
            className="group"
            cursor="pointer"
            position="relative"
            rounded="lg"
            overflow="hidden"
          >
            <Img
              alt="Screenshot of Envelope App"
              src="https://res.cloudinary.com/chakra-ui-pro/image/upload/v1621085270/pro-website/app-screenshot-light_kit2sp.png"
            />
            <Circle
              size="20"
              as="button"
              bg="white"
              shadow="lg"
              color="blue.600"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate3d(-50%, -50%, 0)"
              fontSize="xl"
              transition="all 0.2s"
              _groupHover={{
                transform: 'translate3d(-50%, -50%, 0) scale(1.05)',
              }}
            >
              <VisuallyHidden>Play demo video</VisuallyHidden>
              <FaPlay />
            </Circle>
          </Box>
          */}
        </Box>
      </Box>
    </Box>
  );
};

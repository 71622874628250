import {
  Box,
  ButtonGroup,
  Container,
  IconButton,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

export const Footer = () => (
  <Box bg="brandDark" color="white">
    <Container as="footer" role="contentinfo" py={{ base: "12", md: "16" }}>
      <Stack spacing={{ base: "4", md: "5" }}>
        <Stack justify="space-between" direction="row" align="center">
          <ButtonGroup variant="tertiary.accent">
            <IconButton
              as="a"
              href="https://www.linkedin.com/company/withdatashare"
              aria-label="LinkedIn"
              icon={<FaLinkedin fontSize="1.25rem" />}
            />
            <IconButton
              as="a"
              href="https://github.com/datashare-public"
              aria-label="GitHub"
              icon={<FaGithub fontSize="1.25rem" />}
            />
            <IconButton
              as="a"
              href="https://twitter.com/WithDataShare"
              aria-label="Twitter"
              icon={<FaTwitter fontSize="1.25rem" />}
            />
          </ButtonGroup>
        </Stack>
        <Text fontSize="sm" color="fg.accent.subtle">
          <Link href="/privacy">Privacy Policy</Link>
        </Text>
        <Text fontSize="sm" color="fg.accent.subtle">
          <Link href="mailto:hello@datashare.co">hello@datashare.co</Link>
        </Text>
        <Text fontSize="sm" color="fg.accent.subtle">
          &copy; {new Date().getFullYear()} DataShare, Inc. All rights reserved.
        </Text>
      </Stack>
    </Container>
  </Box>
);

import {
  AspectRatio,
  Button,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import IntegrationsImage from "./images/Integrations.png"

export const Integrations = () => (
  <Container py={{ base: '16', md: '24' }}>
    <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
      <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
        <Stack spacing={{ base: '4', md: '6' }}>
          <Heading size={{ base: 'md', md: 'lg' }}>
            Seamless Integration With Your Exisiting Stack
          </Heading>
          <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
            DataShare works with the database, backend and frontend you already use. We'll fit in perfectly
            with your infrastructure.
          </Text>
        </Stack>
      </Stack>
      <AspectRatio ratio={1}>
        <Image style={{objectFit: "contain"}} src={IntegrationsImage} alt="Integrations" width="100%" height="100%"/>
      </AspectRatio>
    </SimpleGrid>
  </Container>
)
import {
    Box,
    Button,
    Container,
    Heading,
    Icon,
    SimpleGrid,
    Square,
    Stack,
    Text,
  } from '@chakra-ui/react'
  import { FiArrowRight } from 'react-icons/fi'

  const features = [
    {
        name: "Setup Data Sources",
        description: "Link a data source like a database, spreadsheet or warehouse and define your reporting schemas."
    },
    {
        name: "Configure Components",
        description: "Select the charts, tables, exports and data syncs your customers need via our intuitive UI. No code needed."
    },
    {
        name: "Go Live!",
        description: "Give your customers a link to a hosted analytics site, or embed components directly in your app."
    }
  ]
  
  export const Features = () => (
    <Box as="section" bg="white">
      <Container py={{ base: '16', md: '24' }}>
        <Stack spacing={{ base: '12', md: '16' }}>
          <Stack spacing={{ base: '4', md: '5' }} maxW="3xl">
            <Stack spacing="3">
              <Heading size={{ base: 'sm', md: 'md' }}>
                Powerful Data Products Directly In Your App
              </Heading>
            </Stack>
            <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
                Add data products your customers need quickly with minimal engineering
            </Text>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={8} rowGap={{ base: 10, md: 16 }}>
            {features.map((feature, index) => (
              <Stack key={feature.name} spacing={{ base: '4', md: '5' }}>
                <Square
                  size={{ base: '10', md: '12' }}
                  bg="brandDark"
                  borderRadius="lg"
                >
                    <Text color="fg.inverted" fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold">{index+1}</Text>
                </Square>
                <Stack spacing={{ base: '1', md: '2' }} flex="1">
                  <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="medium">
                    {feature.name}
                  </Text>
                  <Text color="fg.muted">{feature.description}</Text>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  )
import { theme as proTheme } from '@chakra-ui/pro-theme'
import { extendTheme, theme as baseTheme } from '@chakra-ui/react'

export const theme = extendTheme(
  {
    colors: {
      ...baseTheme.colors,
      brand: "rgb(71, 222, 255)",
      brandDark: "rgb(12, 140, 188)",
      brandBackgroundLight: "rgb(235, 248, 255)",
    },
  },
  proTheme,
)
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { CHAT_LINK } from "./constants";
import LogoSVG from "./images/logo.svg";

export const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Box as="section">
      <Box as="nav" bg="bg.surface" boxShadow="sm">
        <Container py={{ base: "3", lg: "4" }}>
          <Flex justify="space-between" width="100%">
            <Image src={LogoSVG} height="30" />
            <Text
              fontFamily="comfortaa, sans-serif"
              fontSize={24}
              fontWeight="semibold"
            >
              datashare
            </Text>
            <Button
              onClick={() => (window.location.href = CHAT_LINK)}
              backgroundColor="brandDark"
              style={{ marginLeft: "auto" }}
            >
              Chat With Us
            </Button>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

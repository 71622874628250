import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Container,
} from "@chakra-ui/react"
import { theme } from "./theme"
import { Navbar } from "./Navbar"
import { Hero } from "./Hero"
import { Solutions } from "./Solutions"
import { Simplicity } from "./Simplicity"
import { Integrations } from "./Integrations"
import { Footer } from "./Footer"
import { Features } from './Features'
import '@fontsource/inter';
import '@fontsource/comfortaa';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box bg="brandBackgroundLight">
      <Navbar />
      <Hero />
      <Features />
      {/*<Solutions />
      <Simplicity />*/}
      <Integrations />
      <Footer />
    </Box>
  </ChakraProvider>
)
